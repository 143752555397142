
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93lhI4oPw71MMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/[...slug].vue?macro=true";
import { default as autologinfG6anciy4DMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/auth/autologin.vue?macro=true";
import { default as callbackQNEHb8E4bvMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/auth/callback.vue?macro=true";
import { default as bettingN7bwNqcGk8Meta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/betting.vue?macro=true";
import { default as _91pageIdentity_933mCsBLqCJIMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/bonus/[pageIdentity].vue?macro=true";
import { default as _91categoryIdentity_93ngSzJXnuAzMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/categories/[categoryIdentity].vue?macro=true";
import { default as categoriestAYs9f0mLbMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/categories.vue?macro=true";
import { default as contactzwezxg5lYOMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/contact.vue?macro=true";
import { default as favorites_46clientx2tmNHDafrMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/favorites.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/platform/customer-frontends/jeffybet/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91id_93Da58v2r4ScMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/games/[id].vue?macro=true";
import { default as indexe0TWexfVbTMeta } from "/builds/platform/customer-frontends/jeffybet/pages/index.vue?macro=true";
import { default as loyaltyWRtibeiomoMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/loyalty.vue?macro=true";
import { default as main4hvAJ9F9DMMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/main.vue?macro=true";
import { default as bonusesn7hfuzcUx6Meta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/bonuses.vue?macro=true";
import { default as documentsvyx0VuamSBMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/documents.vue?macro=true";
import { default as historybwXEIwUaTWMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/history.vue?macro=true";
import { default as infohuufjWo4YJMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/info.vue?macro=true";
import { default as limitsviZPZn5j4rMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/limits.vue?macro=true";
import { default as notificationsljnm0ZNt04Meta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/notifications.vue?macro=true";
import { default as securityo06KZWgicgMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/security.vue?macro=true";
import { default as verificationawfTCvZOHxMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/verification.vue?macro=true";
import { default as wallethclbQjclRbMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/wallet.vue?macro=true";
import { default as profile_46clientvxPkQjZ7pFMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile.client.vue?macro=true";
import { default as providersq7Us64Y7gSMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/providers.vue?macro=true";
import { default as _91pageIdentity_93VdPIq3a1cYMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/questions/[pageIdentity].vue?macro=true";
import { default as questionsK7m12G2mYPMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/questions.vue?macro=true";
import { default as recently_45played_46clientJtpsyHzQHQMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/recently-played.client.vue?macro=true";
import { default as _91pageIdentity_93vw9kgB9S7YMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/static/[pageIdentity].vue?macro=true";
import { default as _91identity_93aFSBqQ7HOnMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/tournaments/[identity].vue?macro=true";
import { default as indexhGu8mgx2sRMeta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/tournaments/index.vue?macro=true";
import { default as welcome_45package2bnIwtq0C9Meta } from "/builds/platform/customer-frontends/jeffybet/skeleton/pages/welcome-package.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/[...slug].vue")
  },
  {
    name: "auth-autologin",
    path: "/auth/autologin",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/auth/autologin.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/auth/callback.vue")
  },
  {
    name: "betting",
    path: "/betting",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/betting.vue")
  },
  {
    name: "bonus-pageIdentity",
    path: "/bonus/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/bonus/[pageIdentity].vue")
  },
  {
    name: "categories",
    path: "/categories",
    meta: categoriestAYs9f0mLbMeta || {},
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/categories.vue"),
    children: [
  {
    name: "categories-categoryIdentity",
    path: ":categoryIdentity()",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/categories/[categoryIdentity].vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/contact.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/favorites.client.vue"))
  },
  {
    name: "games-id",
    path: "/games/:id()",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/games/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/platform/customer-frontends/jeffybet/pages/index.vue")
  },
  {
    name: "loyalty",
    path: "/loyalty",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/loyalty.vue")
  },
  {
    name: "main",
    path: "/main",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/main.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile.client.vue")),
    children: [
  {
    name: "profile-bonuses",
    path: "bonuses",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/bonuses.vue")
  },
  {
    name: "profile-documents",
    path: "documents",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/documents.vue")
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/history.vue")
  },
  {
    name: "profile-info",
    path: "info",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/info.vue")
  },
  {
    name: "profile-limits",
    path: "limits",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/limits.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/notifications.vue")
  },
  {
    name: "profile-security",
    path: "security",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/security.vue")
  },
  {
    name: "profile-verification",
    path: "verification",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/verification.vue")
  },
  {
    name: "profile-wallet",
    path: "wallet",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/profile/wallet.vue")
  }
]
  },
  {
    name: "providers",
    path: "/providers",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/providers.vue")
  },
  {
    name: "questions",
    path: "/questions",
    meta: questionsK7m12G2mYPMeta || {},
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/questions.vue"),
    children: [
  {
    name: "questions-pageIdentity",
    path: ":pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/questions/[pageIdentity].vue")
  }
]
  },
  {
    name: "recently-played",
    path: "/recently-played",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/recently-played.client.vue"))
  },
  {
    name: "static-pageIdentity",
    path: "/static/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/static/[pageIdentity].vue")
  },
  {
    name: "tournaments-identity",
    path: "/tournaments/:identity()",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/tournaments/[identity].vue")
  },
  {
    name: "tournaments",
    path: "/tournaments",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/tournaments/index.vue")
  },
  {
    name: "welcome-package",
    path: "/welcome-package",
    component: () => import("/builds/platform/customer-frontends/jeffybet/skeleton/pages/welcome-package.vue")
  }
]